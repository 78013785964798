.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

:root {
  /* colors */
  --gray-100: #f9fafc;
  --purple-100: #fbf7ff;
  --purple-400: #e8d1ff;
  --purple-600: #7000e3;
  --gray-300: #f4f5f7;
  --gray-400: #ebecef;
  --gray-600: #6e7177;
  --gray-700: #35373a;
  --gray-900: #202020;
  --green-100: #f6fcf3;
  --green-600: #70c93c;
  --red-600: #f63041;
  --black: #000;
  --text: #000;
  --underlay: rgba(0, 0, 0, 0.7);
  --orange-600: #ff7a00;

  /* spacing */
  --header-height: 64px;
}
